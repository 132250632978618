<template>
  <div class="content">
    <h1
      class="fw-600 color-text-darker fs-28 lh-48 let-space-05 mb-24"
      v-if="!caseID"
    >{{ casesCountText }}</h1>
    <div :key="forceUpdate">
      <MonitoringCase
        v-for="(inv, index) in cases"
        :key="inv.oid"
        :investigation="inv"
        :firstCase="index === 0"
      />
    </div>
  </div>
  <AcceptModal
    v-if="caseToRemove"
    @closeModal="setCaseToRemove(null)"
    @confirmAnswer="deleteCaseTask"
  >
    <p class="typography-5 fw-600 let-space-12 color-1 mb-24 overflow-hidden">
      Are you sure <br> you want to delete {{ caseToRemove.tag }}?
    </p>
  </AcceptModal>
  <AcceptModal
    v-if="taskToRemove"
    @closeModal="setTaskToRemove(null)"
    @confirmAnswer="deleteCaseTask"
  >
    <p class="typography-5 fw-600 let-space-12 color-1 mb-24 overflow-hidden">
      Are you sure <br> you want to delete this task?
    </p>
  </AcceptModal>
</template>

<script>
import MonitoringCase from '@/components/monitoring/Case';
import AcceptModal from '@/components/app/AcceptModal';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Monitoring',
  components: {
    MonitoringCase,
    AcceptModal
  },
  data () {
    return {
      forceUpdate: 0
    };
  },
  watch: {
    cases () {
      this.forceUpdate += 1;
    }
  },
  computed: {
    ...mapGetters(['caseToRemove', 'taskToRemove', 'monitoredCasesSorted']),
    cases () {
      const monitoredCases = this.monitoredCasesSorted;
      return (this.caseID) ? monitoredCases.filter(inv => inv.oid === this.caseID) : monitoredCases;
    },
    casesCountText () {
      const casesCount = this.cases.length;
      return `${casesCount} case${casesCount === 1 ? '' : 's'}`;
    },
    caseID () {
      return this.$route.params.case_id;
    }
  },
  methods: {
    ...mapActions(['removeMonitoringTasks', 'getMonitoredCases', 'getMonitoringTasks']),
    ...mapMutations(['setSearching', 'setLoadUrl', 'setCaseToRemove', 'setTaskToRemove']),
    async deleteCaseTask () {
      this.setSearching(true);
      this.setLoadUrl(true);
      let payload = {};
      if (this.caseToRemove) {
        payload = { caseID: this.caseToRemove.oid };
      } else {
        payload = { caseID: this.taskToRemove.case_id, taskID: this.taskToRemove.id };
      }
      await this.removeMonitoringTasks(payload);
      await this.getMonitoredCases();
      await this.getMonitoringTasks();
      this.setSearching(false);
      this.setLoadUrl(false);
      this.setCaseToRemove(null);
      this.setTaskToRemove(null);
      if (this.cases.length === 0) this.$router.push({ path: '/monitoring/settings' });
    }
  }
};
</script>
